import { styled } from '@mui/system'
import Hero from '@bluheadless/ui/src/organisms/hero/hero'
import { Root, OverlayContainer } from '@bluheadless/ui/src/organisms/hero/hero.styled'
import {
	Title as HeroBannerTitle,
	Subtitle as HeroBannerSubtitle,
	Description as HeroBannerDescription,
	CtaContainer as HeroBannerCtaContainer,
	Link as HeroBannerLink,
	Button as HeroBannerButton,
} from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'

const shouldForwardProp = (prop) => {
	return !['colorHex'].includes(prop)
}
const Title = styled(HeroBannerTitle)``
const Subtitle = styled(HeroBannerSubtitle)``
const Description = styled(HeroBannerDescription)`
	.MuiTypography-root {
		letter-spacing: var(--letter-spacing);
	}
`
const CtaContainer = styled(HeroBannerCtaContainer)``
const Link = styled(HeroBannerLink)``
const Button = styled(HeroBannerButton, { shouldForwardProp })``

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	pointer-events: auto;
	position: sticky;
	top: var(--header-sticky-top, 0px);
	left: 0;
	padding: var(--spacing-5) calc(var(--spacing) * 2.5);
	height: fit-content;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: var(--spacing-5);
	}
`
const HeroStyled = styled(Hero, Root)`
	&.bottom {
		${Container} {
			top: unset;
			bottom: 0;
		}
	}
	&.sticky-desktop {
		flex-direction: column;
		flex-wrap: nowrap;
		${Container} {
			${({ theme }) => theme.breakpoints.down('md')} {
				position: relative;
				top: 0;
				height: unset;
				padding: var(--spacing-3) calc(var(--spacing) * 2.5);
			}
		}
		${OverlayContainer} {
			${({ theme }) => theme.breakpoints.down('md')} {
				position: relative;
				height: unset;
			}
		}
		${Title} {
			font-size: ${({ theme }) => theme.typography.pxToRem(30)};
			line-height: 38px;
			${({ theme }) => theme.breakpoints.up('md')} {
				font-size: ${({ theme }) => theme.typography.pxToRem(40)};
				line-height: 48px;
			}
		}
		${Subtitle} {
			font-size: var(--font-size-body16);
			letter-spacing: var(--letter-spacing-body);
		}
	}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button, HeroStyled }
